var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showCreateYieldSetting)?_c('ModalCreateYieldSetting',{attrs:{"showModal":_vm.showCreateYieldSetting,"spaceId":_vm.spaceId,"channelId":_vm.yieldSettingsList.selectedChannelId,"voucherId":_vm.voucherId,"meetingtypeId":_vm.selectedMeetingtypeId,"onClickCancel":_vm.hideModal},on:{"yieldSettingCreated":_vm.yieldSettingCreated}}):_vm._e(),_c('ui-section-header',{attrs:{"showAdd":true,"addFunction":() => {
        _vm.setShowCreateYieldSetting(_vm.meetingtypeId)
      }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Pricing & yield settings")]},proxy:true}])}),_c('div',{staticClass:"tabs is-boxed"},[(_vm.voucherId === 0)?_c('ul',[_vm._l((_vm.channels),function(channel,index){return _c('li',{key:index,class:{
          'is-active':
            channel.ChannelId === _vm.yieldSettingsList.selectedChannelId &&
            !_vm.showVouchers,
        }},[_c('a',{on:{"click":function($event){return _vm.showYieldSettings(channel.Id, false)}}},[_vm._v(_vm._s(channel.Name))])])}),(_vm.meetingtypeId === 0)?_c('li',{class:{
          'is-active':
            _vm.yieldSettingsList.selectedChannelId === 0 && _vm.showVouchers,
        }},[_c('a',{on:{"click":function($event){return _vm.showYieldSettings(0, true)}}},[_vm._v("Vouchers")])]):_vm._e()],2):_vm._e()]),(_vm.yieldSettingsList.yieldSettings.length === 0)?_c('div',[_c('message',{attrs:{"message":'No price & yield settings found. To create one, click on the create button below.'}})],1):_vm._e(),_c('div',[(_vm.yieldSettingsList.selectedChannelId > 0 && !_vm.showVouchers)?_c('div',_vm._l((_vm.meetingtypeIds),function(mId){return _c('div',{key:mId,staticClass:"box"},[(_vm.meetingtypeId === 0)?_c('ui-section-header',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm._f("getMeetingtypeName")(mId)))]},proxy:true}],null,true)}):_vm._e(),_c('YieldTable',{attrs:{"showChannelColumn":false,"showMeetingtypeColumn":false,"showVoucherColumn":false,"yieldSettings":_vm.getYieldSettingsFilteredByMeetingtype(mId)}})],1)}),0):_vm._e(),(_vm.yieldSettingsList.selectedChannelId === 0 && _vm.showVouchers)?_c('div',[_c('div',{staticClass:"box"},[(_vm.voucherId === 0)?_c('ui-section-header',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Vouchers")]},proxy:true}],null,false,2669716414)}):_vm._e(),_c('YieldTable',{attrs:{"showChannelColumn":false,"showSpaceColumn":false,"showVoucherColumn":false,"showMeetingtypeColumn":false,"showValidFromColumn":false,"yieldSettings":_vm.getVoucherYieldSettings()}})],1)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }