<template>
  <div>
    <ModalCreateYieldSetting
      v-if="showCreateYieldSetting"
      :showModal="showCreateYieldSetting"
      :spaceId="spaceId"
      :channelId="yieldSettingsList.selectedChannelId"
      :voucherId="voucherId"
      :meetingtypeId="selectedMeetingtypeId"
      :onClickCancel="hideModal"
      @yieldSettingCreated="yieldSettingCreated"
    />

    <ui-section-header
      :showAdd="true"
      :addFunction="
        () => {
          setShowCreateYieldSetting(meetingtypeId)
        }
      "
    >
      <template v-slot:title>Pricing &amp; yield settings</template>
    </ui-section-header>

    <div class="tabs is-boxed">
      <ul v-if="voucherId === 0">
        <li
          v-for="(channel, index) in channels"
          :key="index"
          :class="{
            'is-active':
              channel.ChannelId === yieldSettingsList.selectedChannelId &&
              !showVouchers,
          }"
        >
          <a @click="showYieldSettings(channel.Id, false)">{{
            channel.Name
          }}</a>
        </li>
        <li
          v-if="meetingtypeId === 0"
          :class="{
            'is-active':
              yieldSettingsList.selectedChannelId === 0 && showVouchers,
          }"
        >
          <a @click="showYieldSettings(0, true)">Vouchers</a>
        </li>
      </ul>
    </div>

    <div v-if="yieldSettingsList.yieldSettings.length === 0">
      <message
        :message="'No price &amp; yield settings found. To create one, click on the create button below.'"
      />
    </div>

    <div>
      <div v-if="yieldSettingsList.selectedChannelId > 0 && !showVouchers">
        <div v-for="mId in meetingtypeIds" :key="mId" class="box">
          <ui-section-header v-if="meetingtypeId === 0">
            <template v-slot:title>{{ mId | getMeetingtypeName }}</template>
          </ui-section-header>

          <YieldTable
            :showChannelColumn="false"
            :showMeetingtypeColumn="false"
            :showVoucherColumn="false"
            :yieldSettings="getYieldSettingsFilteredByMeetingtype(mId)"
          />
        </div>
      </div>

      <div v-if="yieldSettingsList.selectedChannelId === 0 && showVouchers">
        <div class="box">
          <ui-section-header v-if="voucherId === 0">
            <template v-slot:title>Vouchers</template>
          </ui-section-header>

          <YieldTable
            :showChannelColumn="false"
            :showSpaceColumn="false"
            :showVoucherColumn="false"
            :showMeetingtypeColumn="false"
            :showValidFromColumn="false"
            :yieldSettings="getVoucherYieldSettings()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import yieldProvider from '@/providers/yield'

const Message = () => import('@/components/UI/Message')
const ModalCreateYieldSetting = () =>
  import('@/components/Yield/ModalCreateYieldSetting')
const YieldTable = () => import('@/components/Yield/YieldTable')

export default {
  components: {
    message: Message,
    ModalCreateYieldSetting,
    YieldTable,
  },

  props: {
    channelId: {
      type: Number,
      default: 0,
    },

    locationId: {
      type: Number,
      default: 0,
    },

    meetingtypeId: {
      type: Number,
      default: 0,
    },

    spaceId: {
      type: Number,
      default: 0,
    },

    voucherId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      selectedMeetingtypeId: this.meetingtypeId,
      selectedYieldSetting: null,
      showCreateYieldSetting: false,
      showVouchers: this.voucherId > 0,
      //yieldSettings: [],
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['locationState']),
    ...mapState('yieldStore', ['yieldSettingsList']),

    channels() {
      if (this.channel) {
        let channels = []
        channels.push(this.channel)

        return channels
      }
      return []
    },

    meetingtypeIds() {
      let meetingtypeIds = []

      if (this.meetingtypeId > 0) {
        meetingtypeIds.push(this.meetingtypeId)
      } else {
        meetingtypeIds = this.locationState.Meetingtypes.map(
          (m) => m.MeetingtypeId
        )
      }

      return meetingtypeIds
    },

    yieldSettingsFilteredByChannel() {
      let self = this
      let ysList = []

      if (self.yieldSettingsList.yieldSettings) {
        ysList = self.yieldSettingsList.yieldSettings

        if (self.yieldSettingsList.selectedChannelId > 0) {
          ysList = ysList.filter(
            (ys) => ys.ChannelId === self.yieldSettingsList.selectedChannelId
          )
        }
      }

      return ysList
    },
  },

  created() {
    if (
      this.locationState &&
      this.voucherId === 0 &&
      this.yieldSettingsList.selectedChannelId === 0
    ) {
      this.yieldSettingsList.selectedChannelId = this.channels[0].Id
    }

    if (this.voucherId > 0) {
      this.yieldSettingsList.showVouchers = true
      this.setYieldSettingsList(this.yieldSettingsList)
    }

    this.getLocationYieldSettings()
  },

  methods: {
    ...mapMutations('yieldStore', [
      'setYieldSettingsState',
      'setYieldSettingsList',
    ]),

    getLocationYieldSettings() {
      let self = this

      yieldProvider.methods
        .getYieldSettings(
          self.locationId,
          self.channelId,
          self.spaceId,
          self.voucherId,
          self.meetingtypeId
        )
        .then((response) => {
          if (response.status === 200) {
            self.yieldSettingsList.yieldSettings = response.data

            self.setYieldSettingsList(self.yieldSettingsList)
          }
        })
    },

    getYieldSettingsFilteredByMeetingtype(meetingtypeId) {
      let self = this
      let yieldSettings = self.yieldSettingsFilteredByChannel

      if (meetingtypeId > 0) {
        yieldSettings = yieldSettings.filter(
          (ys) => ys.MeetingtypeId === meetingtypeId && ys.VoucherId === 0
        )
      }

      return yieldSettings
    },

    getVoucherYieldSettings() {
      let self = this

      return self.yieldSettingsList.yieldSettings.filter(
        (ys) => ys.VoucherId > 0
      )
    },

    yieldSettingCreated(yieldSettings) {
      this.yieldSettingsList.yieldSettings.push(yieldSettings)
      this.goToYieldSettings(yieldSettings)
    },

    goToYieldSettings(yieldSettings) {
      this.setYieldSettingsState({
        isSaving: false,
        isSavingSuccess: false,
        isSavingError: false,
        yieldSettings: yieldSettings,
      })

      this.$router.push({
        name: `settings-yield-detail`,
        params: { yieldId: yieldSettings.Id },
      })
    },

    showYieldSettings(channelId, showVouchers) {
      this.yieldSettingsList.selectedChannelId = channelId
      this.yieldSettingsList.showVouchers = showVouchers

      this.setYieldSettingsList(this.yieldSettingsList)
    },

    setShowCreateYieldSetting(meetingtypeId) {
      this.selectedMeetingtypeId = meetingtypeId
      this.showCreateYieldSetting = true
    },

    hideModal() {
      this.selectedMeetingtypeId = 0
      this.selectedYieldSetting = null
      this.showCreateYieldSetting = false
    },
  },
}
</script>
